import React, { memo, useState } from 'react';
import { SEO, Card, InputField, ExpandingButton } from '@components';

import './contact-us.scss';
import { LandingTopAnimated } from '@containers';
import { sendContactUsFormDataApiAction } from '@api';
const AboutPage = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
    }
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    sendContactUsFormDataApiAction(form)
      .then(r => {
        handleServerResponse(true, "Thank you, we'll contact you shortly !", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <div>
      <SEO title="About" />
      <LandingTopAnimated className="on-contact-us-animated" />
      <div className="on-contact-us-container">
        <Card className="on-normal on-no-animation-normal">
          <div className="on-contact-us-form">
            <div>
              <h1>Contact Us </h1>
              <p>
                Interested in working with us? Send us a message, we'll get back to you right away.
              </p>
            </div>
            <div>
              <form onSubmit={handleOnSubmit} className="form">
                <InputField className="field" text="Name" name="name" id="name" />
                <InputField text="Email" name="email" id="email" />
                <InputField text="Message" name="message" id="message" rows="10" />
                <div className="on-contact-us-from-button">
                  <ExpandingButton
                    type="submit"
                    buttonText="Send"
                    disabled={serverState.submitting}
                  />
                </div>
                {serverState.status && <p className="on-form-submit">{serverState.status.msg}</p>}
              </form>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default memo(AboutPage);
